import React, {useState, useEffect} from 'react'
import {FaBars, FaFacebook, FaInstagram, FaLinkedin, FaSpotify, FaTwitter, FaYoutube} from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavLinks,
  NavItem,
  NavBtn,
  NavBtnLink,
  NavMail,
  NavLinksDom
} from './NavbarElements'
import { animateScroll as scroll } from 'react-scroll';
import {SocialIconLink, SocialMediaWrap} from "./NavbarElements";

const Navbar = ({ toggle, dark }) => {

  const [scrollNav, setScrollNav] = useState(false)

  const changeNav = () => {
      if(window.scrollY >= 600) {
        setScrollNav(true)
      } else {
        setScrollNav(false)
      }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, [])

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  const toggleProjects = () => {
    scroll.scrollTo(720)
  }

  return (
    <>
    <IconContext.Provider value={{ color: '#181818'}}>
      <Nav scrollNav={scrollNav} dark={dark}>
        <NavbarContainer >
          <MobileIcon onClick={toggle}>
            <FaBars color={'inherit'}/>
          </MobileIcon>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar
