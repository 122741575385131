import styled from 'styled-components'

export const SongContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #181818;
  padding: 64px 20px;
  color: #fff;
  width: 100%;
 `

export const SongTitle = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 20px;
  

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }  
`
export const SubTitle = styled.h2`
  color: inherit;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 20px;
`

export const Lyrics = styled.p`
  padding: 20px 0;
  font-size: 18px;
  color: #fff;
  text-align: center;
 font-weight: lighter;
 @media screen and (max-width: 768px) {
  font-size: 14px;
 }
`

export const CoverImg = styled.img`
 max-width: 500px;
 width: 100%;
 height: auto;
 padding-bottom: 20px;
`


export const CoverAndText = styled.div`
 display: flex;
 flex-direction: row;
 width: 100%;
 align-items: center;
 justify-content: center;
`


export const Socials = styled.div`
 display: flex;
 width: 100%;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 padding-bottom: 20px;
`
