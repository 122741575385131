import React, {useState} from 'react'
import Rellax from "rellax";
import { useRef, useEffect } from "react";
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  MountainsImg,
  MountainsGround,
  BackgroundImage, BgImg,

} from './HeroElements'
import bg1 from '../../images/hero/background.svg'
import planets from '../../images/hero/planets.svg'
import kurven from '../../images/hero/kurven.svg'
import dots from '../../images/hero/dots.svg'
import label from '../../images/hero/Label.svg'
import triangles from '../../images/hero/triangles.svg'
import waves from '../../images/hero/waves.svg'


const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

    useEffect(() => {
      const s = 3;

      new Rellax(".animateTitle", {
        speed: 8,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });
      new Rellax(".animateBg1", {
        speed: -5,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });
      new Rellax(".animateBg2", {
        speed: 1,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });
      new Rellax(".animateBg3", {
        speed: 5,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });
      new Rellax(".animateBg4", {
        speed: 2,
        center: false,
        wrapper: null,
        round: true,
        vertical: true,
        horizontal: false
      });
    }, []);

  return (
      <HeroContainer id='home'>
        <HeroBg>
          <BgImg src={bg1}></BgImg>
          <BgImg src={kurven}></BgImg>
          <BgImg className={'animateBg4'} src={triangles}></BgImg>
          <BgImg className={'animateBg3'} src={planets}></BgImg>
          <BgImg className={'animateBg2'} src={waves}></BgImg>
          <BgImg className={'animateBg1'} src={dots}></BgImg>
        </HeroBg>
        <HeroContent className={'animateTitle'}>
          <HeroH1>ALCIS</HeroH1>
          <HeroP>SONGWRITER & PRODUCER</HeroP>
        </HeroContent>
      </HeroContainer>
  )
}

export default Hero
