import React, {useEffect, useState} from 'react'
import Footer from '../components/Footer';
import Hero from '../components/Hero'
import Songs from '../components/Songs'
import Navbar from '../components/Navbar'
import Sidebar from '../components/SideBar'




const Home = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dark = true;
  const displayAttributions = true;

  const toggle = () =>{
    setIsOpen(!isOpen);
  }


  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} dark={dark}/>
      <Hero />
      <Songs />
      <Footer dark={dark} display={displayAttributions}/>
    </>
  )
}

export default Home

