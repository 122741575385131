import React, {useEffect} from 'react'
import {
    CoverImg, Lyrics, SongContainer, SongTitle, SubTitle, CoverAndText, Socials
} from './ServiceElements'

import evergreen from '../../images/album-covers/Evergreen.png'
import paintingpictures from '../../images/album-covers/painting-pictures.png'
import {SocialIconLink} from "../Navbar/NavbarElements";
import {FaSpotify, FaSoundcloud, FaYoutube, FaItunes} from "react-icons/fa";

const Songs = () => {
    return (
        <>
            <SongContainer id='evergreen'>
                <SongTitle>Evergreen</SongTitle>
                <SubTitle><b>Alcis • Tiara</b> • 2020</SubTitle>
                <Socials>
                    <SocialIconLink href='https://open.spotify.com/track/0GvGgZbq4HAXDsfhBDmHva?si=60f5ec71455d4797' target='_blank' arial-label='Spotify'>
                        <FaSpotify color="inherit"/>
                    </SocialIconLink>
                    <SocialIconLink href='https://soundcloud.com/alcis/evergreen-feat-tiara' target='_blank' arial-label='SoundCloud'>
                        <FaSoundcloud color="inherit"/>
                    </SocialIconLink>
                    <SocialIconLink href='https://www.youtube.com/watch?v=6j9r8s3PU1I' target='_blank' arial-label='Youtube'>
                        <FaYoutube color="inherit"/>
                    </SocialIconLink>
                    <SocialIconLink href='https://music.apple.com/de/album/evergreen-feat-tiara/1511045404?i=1511045405' target='_blank' arial-label='Apple Music'>
                        <FaItunes color="inherit"/>
                    </SocialIconLink>
                </Socials>
                <CoverImg src={evergreen}></CoverImg>
                <SubTitle><b>Lyrics</b></SubTitle>
                <Lyrics>
                    [Verse I]<br/>
                    Worried smile, gazing eyes<br/>
                    He’s taking me through paradise<br/>
                    Blue waves, mesmerized<br/>
                    He’s taking me through paradise<br/>
                    <br/><br/>
                    [Prechorus]<br/>
                    (Come with me, come with me) He’s taking me through paradise<br/>
                    (Come with me, Come with me) He’s taking me through paradise<br/>
                    <br/><br/>
                    [Chorus]<br/>
                    Come with me To a land Evergreen<br/>
                    Take my hand (Take my hand) Come with me<br/>
                    <br/><br/>
                    [Verse II]<br/>
                    Fires fade, gone one day<br/>
                    He’s gonna leave he never stays<br/>
                    Different shades, separate ways<br/>
                    He’s gonna leave he never stays<br/>
                    [Prechorus]<br/>
                    (Come with me, come with me) He’s gonna leave he never stays<br/>
                    (Come with me, Come with me) He’s gonna leave he never stays<br/>
                    <br/><br/>
                    [Chorus]<br/>
                    Come with me To a land Evergreen<br/>
                    Take my hand (Take my hand) Come with me<br/>
                    <br/><br/>
                    [Bridge]<br/>
                    Take me away, past the woodlands down to the shore<br/>
                    You cannot stay, but I will always be yours<br/>
                    <br/><br/>
                    [Chorus]<br/>
                    Come with me To a land Evergreen<br/>
                    (Take me away, past the woodlands down to the shore)<br/>
                    Take my hand (Take my hand) Come with me<br/>
                    (You cannot stay, but I will always be yours)<br/>
                    <br/><br/>
                </Lyrics>
            </SongContainer>
            <SongContainer id='pictures'>
                <SongTitle>Painting Pictures</SongTitle>
                <SubTitle><b>Alcis • Danao López</b> • 2019</SubTitle>
                <Socials>
                    <SocialIconLink href='https://open.spotify.com/track/5BoYuGvINfUusbb1BZIsmb?si=ef7bd83a88064499' target='_blank' arial-label='Spotify'>
                        <FaSpotify color="inherit"/>
                    </SocialIconLink>
                    <SocialIconLink href='https://soundcloud.com/alcis/painting-pictures' target='_blank' arial-label='SoundCloud'>
                        <FaSoundcloud color="inherit"/>
                    </SocialIconLink>
                    <SocialIconLink href='https://music.apple.com/de/album/painting-pictures/1462336242?i=1462336247' target='_blank' arial-label='Apple Music'>
                        <FaItunes color="inherit"/>
                    </SocialIconLink>
                </Socials>
                <CoverImg src={paintingpictures}></CoverImg>
                <SubTitle><b>Lyrics</b></SubTitle>
                <Lyrics>
                    [Verse 1]<br/>
                    A feeling of betrayal when you start to smile<br/>
                    'Cause I know I’m the reason, but you're in denial<br/>
                    And then you kiss me just to start this fire<br/>
                    Are you what I need or are you what I desire?<br/>
                    <br/><br/>
                    [Pre-Chorus]<br/>
                    Are you what I need or are you what I desire?<br/>
                    Are you what I need or are you what I desire?<br/>
                    <br/><br/>
                    [Chorus]
                    Lanterns painting pictures like clouds in the sky<br/>
                    All of them are shining and I wonder why<br/>
                    I wonder why (I wonder why)<br/>
                    I wonder why<br/>
                    All of them are shining and I wonder why<br/>
                    I wonder why (I wonder why)<br/>
                    I wonder why<br/>
                    (All of them are shining and I wonder why)<br/>
                    I wonder why (I wonder why)<br/>
                    I wonder why<br/>
                    All of them are shining and I wonder why<br/>
                    <br/><br/>
                    [Verse 2]<br/>
                    You're out in the cold and all you left behind<br/>
                    Is a voice in my head – you’re the thought in my mind<br/>
                    Do I admire just to put you higher?<br/>
                    Are you what I need or are you what I desire?<br/>
                    <br/><br/>
                    [Pre-Chorus]<br/>
                    (Hey-heh-eh-eh-eh-eh-ey, heh-eh-eh-eh-eh-eh-eh-eh-ey)<br/>
                    Are you what I need or are you what I desire?<br/>
                    (Hey-heh-eh-eh-eh-eh-ey, heh-eh-eh-eh-eh-eh-eh-eh-ey)<br/>
                    Are you what I need or are you what I desire?<br/>
                    <br/><br/>
                    [Chorus]<br/>
                    Lanterns painting pictures like clouds in the sky<br/>
                    All of them are shining and I wonder why<br/>
                    (Hey) I wonder why (I wonder why)<br/>
                    I wonder why<br/>
                    All of them are shining and I wonder why<br/>
                    (Hey) I wonder why (I wonder why)<br/>
                    I wonder why<br/>
                    (All of them are shining and I wonder why)<br/>
                    (Hey) I wonder why (I wonder why)<br/>
                    I wonder why<br/>
                    All of them are shining and I wonder why<br/>
                    <br/><br/>
                    (Hey-heh-eh-eh-eh-eh-ey)<br/>
                    Lanterns painting pictures like clouds in the sky<br/>
                    (Heh-eh-eh-eh-eh-eh-eh-eh-ey)<br/>
                    All of them are shining and I wonder why<br/>
                    (Hey-heh-eh-eh-eh-eh-ey)<br/>
                    And then you kiss me just to start this fire<br/>
                    (I wonder why)<br/>
                    I wonder why<br/>
                    (Heh-eh-eh-eh-eh-eh-eh-eh-ey)<br/>
                    All of them are shining and I wonder why<br/>
                    (Hey-heh-eh-eh-eh-eh-ey)<br/>
                    Do I admire just to put you high? (I wonder why)<br/>
                    I wonder why<br/>
                    (Heh-eh-eh-eh-eh-eh-eh-eh-ey)<br/>
                    All of them are shining and I wonder why
                </Lyrics>

            </SongContainer>
        </>

  )
}

export default Songs
