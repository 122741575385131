import React from 'react'
import {animateScroll as scroll} from "react-scroll";
import {
    SidebarContainer,
    Icon,
    CloseIcon,
    SidebarWrapper,
    SidebarMenu,
    SidebarLink,
    SideBtnWrap,
    NavMail
} from './SidebarElements'
import {NavLogo} from "../Navbar/NavbarElements";
import {FaInstagram, FaSpotify, FaItunes} from 'react-icons/fa'
import {IconContext} from 'react-icons/lib'
import {SocialIconLink, SocialMediaWrap} from '../Navbar/NavbarElements'


const Sidebar = ({isOpen,toggle}) => {
    const toggleHome = () => {
        scroll.scrollToTop();
    }

    const toggleProjects = () => {
        scroll.scrollTo(720)
    }
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
            <SocialIconLink href='https://open.spotify.com/artist/2vwxLoLJxaC78tVhqmdgRW?si=WeylpRhsQyWn_HMaCkE1Mg' target='_blank' arial-label='Spotify'>
                <FaSpotify color="inherit"/>
            </SocialIconLink>
            <SocialIconLink href='https://www.instagram.com/alcismusic' target='_blank' arial-label='Instagram'>
                <FaInstagram color="inherit"/>
            </SocialIconLink>
            <SocialIconLink href='https://music.apple.com/de/artist/alcis/1462336243' target='_blank' arial-label='Apple Music'>
                <FaItunes color="inherit"/>
            </SocialIconLink>
        </SidebarMenu>
        <SideBtnWrap>
            <NavMail href='mailto:info@alcis.ch'>Contact</NavMail>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
