import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa'
import {
  FooterContainer,
  FooterWrap,
  FooterLinkWrapper,
  FooterLinkItems,
  FooterLinkContainer,
  FooterLinkTitle,
  FooterLink,
  SocialIconLink,
  SocialIcons,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  Attributions,
  Att
} from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';

const Footer = ({dark, display}) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer dark={dark}>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>
              Alcis
            </SocialLogo>
            <Attributions display={display}>
              <p>Attributions:</p>
              <Att href='https://www.freepik.com/vectors/music'  target='_blank'>Music vector created by freepik</Att>
            </Attributions>
            <WebsiteRights>
              Tim Ogi © {new Date().getFullYear()} All rights reserved.

            </WebsiteRights>

          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer;
